import { FunctionComponent, useState } from "react";
import styles from "../styles/DownloadLink.module.css";

export enum FileType {
    BBCLASSIC_INSTALLER = "bbclassic_installer",
    BBDOC = "bbdoc",
}

interface DownloadLinkProps {
    fileType: FileType,
    downloadUrl?: string,
    handleClick?: (fileType: FileType) => void;
}
 
const DownloadLink: FunctionComponent<DownloadLinkProps> = ({fileType, downloadUrl, handleClick}) => {
    const [fileIcons] = useState<{[key: string]: Record<string, string>}>({
        [FileType.BBCLASSIC_INSTALLER]: {
            icon: "https://frontend-assets.buildbox.com/web-gen-ai/BuildboxClassicLogo.svg",
            label: "Buildbox Classic Installer",
        },
        [FileType.BBDOC]: {
            icon: "https://frontend-assets.buildbox.com/web-gen-ai/bbdoc@2x.png",
            label: "Your game.bbdoc",
        },
    })

   
    return (
        <div className={styles.downloadLink}>
            <img className={styles.downloadLinkIcon} width={18} src={fileIcons[fileType].icon} alt={fileIcons[fileType].label} /> <a href="#/" onClick={() => handleClick?.(fileType)}>{fileIcons[fileType].label}</a>
        </div>
    );
}
 
export default DownloadLink;