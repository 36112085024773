import { ReactElement, ReactNode, useEffect } from "react";

import { BBAuthFrame, AuthResult } from "bb-auth-frame/component";
import { BBPlatformClient, TimestampedTokens } from "../api/BBPlatform";
import { EnvironmentModes } from "bb-auth-frame/bb-post-message";
import Env from "../Env";
import { sendCustomEvent } from "../api/GoogleAnalyticsWrapper";
import useBBAIStore from "../store";

const containerStyles = {
  maxWidth: "680px",
  height: "40%",
  // height: "auto",
  margin: "auto",
};

type Props = {
  guestAccountMode: boolean;
  createAccountMode: boolean;
  tokens: TimestampedTokens | null;
  onTokenChange: (newTokens: TimestampedTokens | null) => void;
  children?: ReactNode | ReactNode[];
  nullifyTokens: number;
};

export function renderChildren(
  isAuthed: boolean,
  children: ReactNode | ReactNode[] | undefined
): ReactElement | undefined {
  if (isAuthed) {
    return <>{children}</>;
  }
}

export function AuthWrapper({
  tokens,
  guestAccountMode,
  createAccountMode,
  children,
  onTokenChange,
  nullifyTokens,
}: Props): ReactElement {
  const isAuthed = tokens !== null || BBPlatformClient.isBBWorldMode();

  const bbaiStore = useBBAIStore();

  function handleAuthSuccess(tokens: AuthResult) {
    console.debug(`[AuthWrapper].handleAuthSuccess]`);
    const timestamped: TimestampedTokens = { ...tokens, timestamp: Date.now() };
    sendCustomEvent("account_login");
    // TODO CT: handle account_create when able to detect
    BBPlatformClient.setTokens(timestamped);
    onTokenChange(timestamped);

    console.log("is tokens: ", tokens.isGuest);
    bbaiStore.setGuest(tokens.isGuest);
  }
  function handleAuthFail(message: string) {
    console.error(`[AuthWrapper] auth failure: ${message}`);
    BBPlatformClient.setTokens(null);
    onTokenChange(null);
  }

  useEffect(() => {
    if (guestAccountMode) {
      containerStyles.height = "90%";
    }
  }, [guestAccountMode]);

  // useEffect(()=> {
  //   // check auth status every <authCheckPeriodMS>
  //   const intervalId = setInterval(()=>{
  //     setTokens(BBPlatformClient.getUnexpiredTokens())
  //     console.debug(`[AuthWrapper] Auth status check: ${tokens !== null}-${isAuthed}`)
  //   }, authCheckPeriodMS)
  //   return () => {
  //     clearInterval(intervalId)
  //   }
  // }, [isAuthed, tokens, setTokens, authCheckPeriodMS])
  // useEffect(()=> {
  //   if (nullifyTokens > 0) {
  //     setTokens(BBPlatformClient.getUnexpiredTokens())
  //   }
  // }, [nullifyTokens, isAuthed, tokens, setTokens])

  return (
    <>
      {renderChildren(isAuthed, children)}
      {!BBPlatformClient.isBBWorldMode() ? (
        <BBAuthFrame
          containerStyles={containerStyles}
          forceIframeReload={nullifyTokens}
          guestAccountMode={guestAccountMode}
          startInCreateMode={createAccountMode}
          key={"authframe"}
          tokens={tokens}
          onAuthSuccess={handleAuthSuccess}
          onAuthFail={handleAuthFail}
          environment={Env.AOB_ENV as EnvironmentModes}
        />
      ) : undefined}
    </>
  );
}
