import { useEffect, useState } from "react";
import {
  bbaiPromptOptions,
  bbaiPromptStepStatus,
} from "../constants/bbai.prompts";
import useBBAIStore from "../store";
import CircularProgress from "./CircularProgress";

export type GenProgressProps = {
  charProgress: number;
  enemyProgress: number;
  bgProgress: number;
  charProgressQueue: number | null;
  enemyProgressQueue: number | null;
  bgProgressQueue: number | null;
  // promptStage: bbaiPromptStepStatus;
};

type Props = {
  promptStage: bbaiPromptStepStatus;
};

const GenerationProgressReport = ({ promptStage }: Props) => {
  const [agregateProgress, setAgregateProgress] = useState<number | null>(null);
  const [queue, setQueue] = useState<number | null>(0);
  const bbaiStore = useBBAIStore();

  useEffect(() => {
    if (bbaiStore.generationProgress) {
      if (promptStage === bbaiPromptStepStatus.CHAR_PROGRESS) {
        setAgregateProgress(bbaiStore.generationProgress.charProgress);
        setQueue(bbaiStore.generationProgress.charProgressQueue);
      } else if (promptStage === bbaiPromptStepStatus.ENEMY_PROGRESS) {
        setAgregateProgress(bbaiStore.generationProgress.enemyProgress);
        setQueue(bbaiStore.generationProgress.enemyProgressQueue);
      } else if (promptStage === bbaiPromptStepStatus.BACKGROUND_PROGRESS) {
        setAgregateProgress(bbaiStore.generationProgress.bgProgress);
        setQueue(bbaiStore.generationProgress.bgProgressQueue);
      } else {
        setAgregateProgress(null);
      }
    }
    // eslint-disable-next-line
  }, [
    bbaiStore.generationProgress,
    bbaiStore.generationProgress.bgProgress,
    bbaiStore.generationProgress.charProgress,
    bbaiStore.generationProgress.enemyProgress,
    bbaiStore.generationProgress.bgProgressQueue,
    bbaiStore.generationProgress.charProgressQueue,
    bbaiStore.generationProgress.enemyProgressQueue,
  ]);

  return (
    <>
      {bbaiPromptOptions[promptStage].prompt}{" "}
      {agregateProgress !== null ? (
        <>
          {agregateProgress > 0 ? (
            <CircularProgress progress={agregateProgress} />
          ) : queue !== 0 ? (
            <>You're in the Queue: {queue}</>
          ) : (
            <CircularProgress progress={agregateProgress} />
          )}
        </>
      ) : null}
    </>
  );
};

export default GenerationProgressReport;
