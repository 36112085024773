import { ReactElement, useState, useEffect, useRef, useCallback } from "react";
import { Col, Form, Input, Row, notification, Spin, InputRef } from "antd";

// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import styles from "../styles/PlaceholderFormComponent.module.css";
import PromptMessage, { PromptMessageProps } from "./PromptMessage";
import {
  bbaiUser,
  bbaiPromptOptions,
  bbaiPromptStepStatus,
  defaultUser,
} from "../constants/bbai.prompts";
import { validateLength } from "../utils/validationForm";
import setIntervalAsync from "../hooks/setIntervalAsync";

import { BBPlatformClient } from "../api/BBPlatform";

import { FinalOptionsType } from "./FinalOptions";
import { ClaimLoginSkipOptionsType } from "./ClaimLoginSkipOptions";
import { FileType } from "./DownloadLink";
import useBBAIStore from "../store";
import useIsMobile from "../hooks/useIsMobile";
import { NavButtons } from "./NavButtons";
import { handlePromptStepAnalytics } from "../api/GoogleAnalyticsWrapper";
import classnames from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import Env from "../Env";
import useWindowDimensions from "../hooks/useWindowDimensions";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export interface Props {
  unauthorizedErrorHandler?: () => void;
  onClaimRequest: (createAccountMode: boolean) => void;
}

export function PlaceholderFormComponent(props: Props): ReactElement {
  const { onClaimRequest, unauthorizedErrorHandler } = props;
  const bbaiStore = useBBAIStore();
  const [api, contextHolder] = notification.useNotification();
  const [bbDoc, setBBDoc] = useState<any>(null);
  const [inputFocused, setInputFocused] = useState<boolean>(false)
  // const [bbdocId , bbaiStore.setBBWorldBBDocId] = useState<string>("");
  // const [charProgress, setCharProgress] = useState<number>(0);
  // const [enemyProgress, setEnemyProgress] = useState<number>(0);
  // const [bgProgress, setBgProgress] = useState<number>(0);
  // const [charProgressQueue, setCharProgressQueue] = useState<number | null>(
  //   null
  // );
  // const [enemyProgressQueue, setEnemyProgressQueue] = useState<number | null>(
  //   null
  // );
  // const [bgProgressQueue, setBgProgressQueue] = useState<number | null>(null);

  const { height, width } = useWindowDimensions();
  const [inputValue, setInputValue] = useState("");
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const [displayFinalOptions, setDisplayFinalOptions] =
    useState<boolean>(false);
  const isMobile = useIsMobile();
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    rePopulateInformation();
    // eslint-disable-next-line
  }, []);

  const rePopulateInformation = async () => {
    // // if any char itmes is null restart something went wrong.
    try {
      if (
        bbaiStore.charAsset === null ||
        bbaiStore.enemyAsset === null ||
        bbaiStore.bgAsset == null
      ) {
        bbaiStore.initial();
      } else if (
        bbaiStore.userBgSelectedImage === "" ||
        bbaiStore.userCharSelectedImage === "" ||
        bbaiStore.userEnemySelectedImage === ""
      ) {
        // await handleGetImages ()
        bbaiStore.setPolling(true);
      } else if (
        bbaiStore.isGuest === false &&
        bbaiStore.openBit === true &&
        bbaiStore.promptStepStatus === bbaiPromptStepStatus.DONE
      ) {
        window.location.replace(
          `${Env.BB_DEEP_LINK}${
            bbaiStore.bbworldBBDocId ? bbaiStore.bbworldBBDocId : ""
          }`
        );
      } else if (
        bbaiStore.promptStepStatus === bbaiPromptStepStatus.DONE ||
        bbaiPromptStepStatus.DOWNLOAD_BBDOC === bbaiStore.promptStepStatus
      ) {
        await handleGetImages();
        const bbdocWorldId = await getBBDOC();
        bbaiStore.setBBWorldBBDocId(bbdocWorldId);
      } else {
        await handleGetImages();
        bbaiStore.setPolling(true);
      }
    } catch (error) {
      console.error(error);
      bbaiStore.initial();
      unauthorizedErrorHandler?.();
    }
  };

  const blurKeyboard = () => {
    // we need to wait a frame as react is likley mid-rendering when this is called
    requestAnimationFrame(()=> {
      const active = document.activeElement as HTMLElement | null
      if (active !== null) {
        active.blur()
      }
      else {
        console.warn("[blurKeyboard] activeElement is null - cant blur keyboard")
      }
    })
  }

  const hasEmptyScrollSpace = () => {
    const scrollParent = bottomRef?.current?.parentElement
    return scrollParent !== undefined && scrollParent !== null && scrollParent.scrollHeight <= scrollParent.clientHeight
  }

  const scrollIntoView = function() {
    if (!hasEmptyScrollSpace()) {
      // don't scroll if the keyboard is open - it does weird stuff on iOS
      bottomRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }

  const handleKeyDown = async (event: any) => {
    if (bbaiStore.isLoading || bbaiStore.completed || displayFinalOptions) {
      return;
    }
    if (event.key === "Enter") {
      handleUserInput(event.target.value);
    }
  };

  const handleSendClick = async (event: any) => {
    if (bbaiStore.isLoading || bbaiStore.completed || displayFinalOptions) {
      return;
    }
    handleUserInput(inputValue);
  };

  const handleUserInput = (value: any) => {
    const passed = validateLength(value);
    if (!passed) {
      bbaiStore.addPrompt({
        message: bbaiPromptOptions[bbaiStore.promptStepStatus].errPrompt,
        timestamp: Date.now().toString(),
        user: bbaiUser,
        promptStage: bbaiPromptStepStatus.INITIAL,
      });
    } else {
      handlePromptStepAnalytics(bbaiStore.promptStepStatus, value);
      if (bbaiPromptStepStatus.INITIAL === bbaiStore.promptStepStatus) {
        bbaiStore.setUserCharPrompt(value);
      } else if (bbaiPromptStepStatus.ENEMY === bbaiStore.promptStepStatus) {
        bbaiStore.setUserEnemyPrompt(value);
      } else if (
        bbaiPromptStepStatus.BACKGROUND === bbaiStore.promptStepStatus
      ) {
        bbaiStore.setUserBgPrompt(value);
      }
      handleAddPrompt(value, bbaiStore.promptStepStatus, true);
      setInputValue("");
    }

    if (isMobile && bbaiStore.promptStepStatus !== bbaiPromptStepStatus.INITIAL && bbaiStore.promptStepStatus !== bbaiPromptStepStatus.ENEMY) {
      blurKeyboard()
    }
  };

  const onChange = (event: any) => {
    if (bbaiStore.isLoading || bbaiStore.completed || displayFinalOptions) {
      return;
    }
    setInputValue(event.target.value);
  };
  useEffect(() => {
    if (bbaiStore.messages) {
      scrollIntoView()
    }
    // eslint-disable-next-line
  }, [bbaiStore.messages]);

  const updateState = useCallback(async () => {
    try {
      if (!bbaiStore.polling) {
        return;
      }

      let tempCharProgres = null;
      let tempEnemyProgres = null;
      let tempBGProgres = null;
      const temp = bbaiStore.generationProgress;

      if (bbaiStore.charAsset !== null) {
        tempCharProgres = await BBPlatformClient.getAssetGenerationProgress(
          bbaiStore.charAsset.job.jobId
        );
        temp.charProgress = Math.floor(
          tempCharProgres.ImageGenerationJob.Progress * 100
        );
        temp.charProgressQueue = tempCharProgres.Queue;
      }
      if (bbaiStore.enemyAsset !== null) {
        tempEnemyProgres = await BBPlatformClient.getAssetGenerationProgress(
          bbaiStore.enemyAsset.job.jobId
        );
        temp.enemyProgress = Math.floor(
          tempEnemyProgres.ImageGenerationJob.Progress * 100
        );
        temp.enemyProgressQueue = tempEnemyProgres.Queue;
      }
      if (bbaiStore.bgAsset !== null) {
        tempBGProgres = await BBPlatformClient.getAssetGenerationProgress(
          bbaiStore.bgAsset.job.jobId
        );
        temp.bgProgress = Math.floor(
          tempBGProgres.ImageGenerationJob.Progress * 100
        );
        temp.bgProgressQueue = tempBGProgres.Queue;
      }

      bbaiStore.setGenerationProgress(temp);

      if (
        tempCharProgres?.ImageGenerationJob.Status === "COMPLETE" &&
        tempEnemyProgres?.ImageGenerationJob.Status === "COMPLETE" &&
        tempBGProgres?.ImageGenerationJob.Status === "COMPLETE"
      ) {
        bbaiStore.setPolling(false);
        bbaiStore.setPromptStepState(bbaiPromptStepStatus.SELECT_ASSETS);
      }
    } catch (error) {
      console.error(error);
      bbaiStore.setPolling(false);
      bbaiStore.setPromptStepState(bbaiPromptStepStatus.INITIAL);
      unauthorizedErrorHandler?.();
    }

    // eslint-disable-next-line
  }, [
    bbaiStore.polling,
    bbaiStore.bgAsset,
    bbaiStore.charAsset,
    bbaiStore.enemyAsset,
  ]);

  useEffect(() => {}, [bbaiStore.polling]);

  setIntervalAsync(updateState, 3000, bbaiStore.polling);

  const handleSelectedAsset = (
    image: string,
    assetType: string,
    idx: number
  ) => {
    if (assetType === "SELECT_CHARACTER") {
      bbaiStore.setUserCharSelectedImage(image, idx);
    }
    if (assetType === "SELECT_ENEMY") {
      bbaiStore.setUserEnemySelectedImage(image, idx);
    }
    if (assetType === "SELECT_BACKGROUND") {
      bbaiStore.setUserBgSelectedImage(image, idx);
    }
  };

  const handleAddPrompt = (
    userPrompt: string = "",
    typeStatus: bbaiPromptStepStatus,
    userEntry: boolean
  ) => {
    const lastMessage = bbaiStore.messages[bbaiStore.messages.length - 1];
    if (
      !userEntry &&
      lastMessage !== undefined &&
      lastMessage.promptStage === typeStatus &&
      lastMessage.user.id === "1"
    ) {
      console.warn(
        `[handleAddPrompt] called when the last prompt added is the same stage. ignoring to avoid duplicates`
      );
      return;
    }

    let prompt: string | ReactElement = userEntry
      ? userPrompt
      : bbaiPromptOptions[bbaiPromptStepStatus[typeStatus]].prompt;

    const userContent = userEntry ? defaultUser : bbaiUser;

    bbaiStore.addPrompt({
      message: prompt,
      timestamp: Date.now().toString(),
      user: userContent,
      promptStage: typeStatus,
    });
  };

  // Lets remove this, no point to this...
  useEffect(() => {
    console.debug(
      `[PlaceholderFormComponent].useEffect - promptStepStatus] ${bbaiStore.promptStepStatus}`
    );
    switch (bbaiStore.promptStepStatus) {
      case bbaiPromptStepStatus.INITIAL:
        bbaiStore.initial();
        break;
      case bbaiPromptStepStatus.ENEMY:
        handleAddPrompt("", bbaiPromptStepStatus.CHAR_PROGRESS, false);
        handleAddPrompt("", bbaiPromptStepStatus.ENEMY, false);
        break;
      case bbaiPromptStepStatus.BACKGROUND:
        handleAddPrompt("", bbaiPromptStepStatus.ENEMY_PROGRESS, false);
        handleAddPrompt("", bbaiPromptStepStatus.BACKGROUND, false);
        break;
      case bbaiPromptStepStatus.GENERATE_ASSETS:
        handleAddPrompt("", bbaiPromptStepStatus.BACKGROUND_PROGRESS, false);
        handleAddPrompt("", bbaiPromptStepStatus.GENERATE_ASSETS, false);
        bbaiStore.setGenerateAssets(true);
        break;
      case bbaiPromptStepStatus.SELECT_ASSETS:
        handleSelectAssetPrompts();
        break;
      case bbaiPromptStepStatus.BUILD_BBDOC:
        handleBuildBBDoc();
        break;
      case bbaiPromptStepStatus.CLAIM_LOGIN_SKIP:
        handleAddPrompt("", bbaiPromptStepStatus.CLAIM_LOGIN_SKIP, false);
        break;
      case bbaiPromptStepStatus.CLAIM_ACCOUNT:
        // console.log("Claiminng guest account and bbdoc");
        // handleBuildBBDoc() // this willl also claim the item
        handleBBDocClaimnAndAccount();
        break;
      case bbaiPromptStepStatus.LOGIN_ACCOUNT:
        handleBBDocClaimnAndAccount();
        break;
      case bbaiPromptStepStatus.DOWNLOAD_BBDOC:
        handleAddPrompt("", bbaiPromptStepStatus.DOWNLOAD_BBDOC, false);
        handleDownloadFeature();
        break;
      case bbaiPromptStepStatus.COMPLETE:
        // handleAddPrompt("", bbaiPromptStepStatus.COMPLETE, false)
        // console.log("Done");
        bbaiStore.setPromptStepState(bbaiPromptStepStatus.DONE);
        break;
      default:
        console.warn(
          `[PlaceholderFormComponent].useEffect] unhandled step ${bbaiStore.promptStepStatus}`
        );
    }
    // eslint-disable-next-line
  }, [bbaiStore.promptStepStatus]);

  const handleSelectAssetPrompts = async () => {
    try {
      await handleGetImages();

      handleAddPrompt("", bbaiPromptStepStatus.BUILD_REGERNATE_RESTART, false);
      bbaiStore.setPromptStepState(
        bbaiPromptStepStatus.BUILD_REGERNATE_RESTART
      );
    } catch (error) {
      console.error("Error: ", error);

      bbaiStore.loadingState(false);
      bbaiStore.setErrorState({
        title: "Error Retrieving Generated Assets",
        message: bbaiPromptOptions[bbaiStore.promptStepStatus].errPrompt,
      });
      unauthorizedErrorHandler?.();
    }
  };

  const handleGetImages = async () => {
    if (
      bbaiStore.bgAsset === null ||
      bbaiStore.charAsset === null ||
      bbaiStore.enemyAsset === null
    ) {
      console.error("Assets are null", bbaiStore.error);
      return;
    }
    const res = await Promise.all([
      BBPlatformClient.getAssetImages(bbaiStore.charAsset.job.jobId),
      BBPlatformClient.getAssetImages(bbaiStore.enemyAsset.job.jobId),
      BBPlatformClient.getAssetImages(bbaiStore.bgAsset.job.jobId),
    ]).catch((err) => {
      // any error fatal
      unauthorizedErrorHandler?.();
      throw err;
    });

    // TODO:  handle potential error case.
    let resCharImages = res[0] as { jobId: string; presignedUrls: string[] };
    let resEnemyImages = res[1] as { jobId: string; presignedUrls: string[] };
    let resBGImages = res[2] as { jobId: string; presignedUrls: string[] };

    bbaiStore.setUserBgSelectedImage(
      resBGImages.presignedUrls[0],
      bbaiStore.charImageIdx
    );
    bbaiStore.setUserCharSelectedImage(
      resCharImages.presignedUrls[0],
      bbaiStore.enemyImageIdx
    );
    bbaiStore.setUserEnemySelectedImage(
      resEnemyImages.presignedUrls[0],
      bbaiStore.bgImageIdx
    );

    bbaiStore.handleSelectPrompt(
      bbaiPromptStepStatus.SELECT_CHARACTER,
      resCharImages.presignedUrls
    );
    bbaiStore.handleSelectPrompt(
      bbaiPromptStepStatus.SELECT_ENEMY,
      resEnemyImages.presignedUrls
    );
    bbaiStore.handleSelectPrompt(
      bbaiPromptStepStatus.SELECT_BACKGROUND,
      resBGImages.presignedUrls
    );
  };

  const handleBuildBBDoc = async () => {
    try {
      if (
        bbaiStore.userCharSelectedImage === "" ||
        bbaiStore.userEnemySelectedImage === "" ||
        bbaiStore.userBgSelectedImage === ""
      ) {
        console.error("Images are empty and not seleected");
        return;
      }

      bbaiStore.loadingState(true);

      const bbworldBBDocId = await getBBDOC();

      bbaiStore.loadingState(false);
      bbaiStore.setBBWorldBBDocId(bbworldBBDocId);

      if (isMobile) {
        bbaiStore.setPromptStepState(bbaiPromptStepStatus.DOWNLOAD_BBDOC);
      } else {
        bbaiStore.setPromptStepState(bbaiPromptStepStatus.CLAIM_LOGIN_SKIP);
      }
    } catch (error) {
      console.error("Error: ", error);

      bbaiStore.loadingState(false);
      bbaiStore.setErrorState({
        title: "Broken BBDOC",
        message: bbaiPromptOptions[bbaiStore.promptStepStatus].errPrompt,
      });
      unauthorizedErrorHandler?.();
    }
  };

  const getBBDOC = async (): Promise<string> => {
    try {
      const res = await BBPlatformClient.createBBDocRandomFile({
        characterImage: bbaiStore.userCharSelectedImage,
        obstacleImage: bbaiStore.userEnemySelectedImage,
        backgroundImage: bbaiStore.userBgSelectedImage,
      });

      if (res.projectBBDocUrl === undefined) {
        console.error("BBDOC URL is undefined");
        throw new Error("BBDOC URL is undefined");
      }

      // console.log("res: ", res);
      setBBDoc(res.projectBBDocUrl);
      return res.bbworldBBDocId;
    } catch (error) {
      console.error("Error: ", error);
      throw error;
    }
  };

  const handleClickDownloadBBDOC = async (fileType: FileType) => {
    // console.log("e", fileType);

    if (isMobile) {
      bbaiStore.setOpenBit(true);
      onClaimRequest(true);
    } else if (fileType === FileType.BBDOC) {
      downloadBBDOC();
    } else if (fileType === FileType.BBCLASSIC_INSTALLER) {
      await BBPlatformClient.downloadBuildboxClient(null, "BB2");
    }

    // bbaiStore.setPromptStepState(bbaiPromptStepStatus.COMPLETE)
  };

  const downloadBBDOC = () => {
    if (!bbDoc) {
      unauthorizedErrorHandler?.();
      handleBuildBBDoc();
      return;
    }
    const a = document.createElement("a");
    a.download = bbDoc;
    a.href = bbDoc;
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleDownloadFeature = () => {
    if (bbaiStore.completed) {
      console.warn(
        `[handleDownloadFeature] already called/completed - ignoring`
      );
      return;
    }

    handlePromptStepAnalytics(bbaiPromptStepStatus.COMPLETE);
    bbaiStore.downloadTemplate(true);

    bbaiStore.completedPrompt({
      message: isMobile
        ? bbaiPromptOptions["COMPLETE_MOBILE"].prompt
        : bbaiPromptOptions["COMPLETE"].prompt,
      timestamp: Date.now().toString(),
      user: bbaiUser,
      promptStage: bbaiPromptStepStatus.COMPLETE,
    });

    bbaiStore.setPromptStepState(bbaiPromptStepStatus.COMPLETE);
  };

  // handle BUILD, REGENERATE, RESTART from click events
  const handleBuildRegenerateRestart = (e: FinalOptionsType) => {
    if (e === FinalOptionsType.BUILD) {
      bbaiStore.setPromptStepState(bbaiPromptStepStatus.BUILD_BBDOC);
      handleAddPrompt("BUILD", bbaiPromptStepStatus.BUILD_BBDOC, true);
    } else if (e === FinalOptionsType.REGENERATE) {
      // bbaiStore.handleRestartRegenerate(bbaiPromptStepStatus["GENERATE_ASSETS"])
      handleAddPrompt(
        "REGENERATE",
        bbaiPromptStepStatus["GENERATE_ASSETS"],
        true
      );
      regenerateAssets();
    } else if (e === FinalOptionsType.RESTART) {
      // bbaiStore.handleRestartRegenerate(bbaiPromptStepStatus["INITIAL"])
      // handleAddPrompt("RESTART", bbaiPromptStepStatus["INITIAL"], true)
      bbaiStore.initial();
    }
  };

  const handleClaimLoginSkip = (e: ClaimLoginSkipOptionsType) => {
    if (e === ClaimLoginSkipOptionsType.CLAIM) {
      console.debug(`[handleClaimLoginSkip] CLAIM`);
      handlePromptStepAnalytics(bbaiPromptStepStatus.CLAIM_ACCOUNT);
      bbaiStore.setPromptStepState(bbaiPromptStepStatus.CLAIM_ACCOUNT);
    } else if (e === ClaimLoginSkipOptionsType.LOGIN) {
      console.debug(`[handleClaimLoginSkip] LOGIN`);
      handlePromptStepAnalytics(bbaiPromptStepStatus.LOGIN_ACCOUNT);
      bbaiStore.setPromptStepState(bbaiPromptStepStatus.LOGIN_ACCOUNT);
    } else {
      console.debug(`[handleClaimLoginSkip] DOWNLOAD`);
      handlePromptStepAnalytics(bbaiPromptStepStatus.DOWNLOAD_BBDOC);
      bbaiStore.setPromptStepState(bbaiPromptStepStatus.DOWNLOAD_BBDOC);
    }
  };

  const openNotificationWithIcon = () => {
    api["error"]({
      message: bbaiStore.error?.title,
      description: bbaiStore.error?.message,
    });

    bbaiStore.setErrorState(undefined);
  };

  const handleBBDocClaimnAndAccount = async () => {
    try {
      // Logic to clamim the acocunt and the asset saved online.

      bbaiStore.loadingState(true);
      handlePromptStepAnalytics(bbaiStore.promptStepStatus);
      if (bbaiPromptStepStatus.CLAIM_ACCOUNT === bbaiStore.promptStepStatus) {
        console.debug(
          "[PlaceholderComponent].handleBBDocClaimnAndAccount] CLAIM clicked"
        );
        onClaimRequest(true);
      } else if (
        bbaiPromptStepStatus.LOGIN_ACCOUNT === bbaiStore.promptStepStatus
      ) {
        console.debug(
          "[PlaceholderComponent].handleBBDocClaimnAndAccount] LOGIN clicked"
        );
        onClaimRequest(false);
      } else {
        throw Error("No BBDOC or BBCLASSIC_INSTALLER selected");
      }
      // console.log("res: ", res)

      bbaiStore.loadingState(false);
      bbaiStore.setPromptStepState(bbaiPromptStepStatus.DOWNLOAD_BBDOC);
    } catch (error) {
      console.error("Error: ", error);

      bbaiStore.loadingState(false);
      bbaiStore.setErrorState({
        title: "Error Claiming Account",
        message: bbaiPromptOptions[bbaiStore.promptStepStatus].errPrompt,
      });
      unauthorizedErrorHandler?.();
    }
  };

  const handleInputDisplay = (renderFinalOptions: boolean) => {
    setDisplayFinalOptions(renderFinalOptions);
  };

  const genJobAssets = async (
    userPrompt: string,
    genType: bbaiPromptStepStatus,
    regen: boolean = false
  ) => {
    // console.log("genJobAssets: ", userPrompt, genType);
    const seed = Math.floor(Math.random() * 1000000);
    try {
      if (genType === bbaiPromptStepStatus.CHARACTER) {
        const genJob = await BBPlatformClient.getGenerateAsset({
          prompt: userPrompt,
          costInVC: 0,
          disableSuggestedOptimizations: false,
          jobId: uuidv4(),
          width: 512,
          height: 512,
          seed: seed,
          removeBackground: true,
        });
        bbaiStore.setCharAsset(genJob);
        bbaiStore.setPolling(true);
        if (regen) {
          return;
        }
        bbaiStore.setPromptStepState(bbaiPromptStepStatus.ENEMY);
      } else if (genType === bbaiPromptStepStatus.ENEMY) {
        const enemyAssetTemp = await BBPlatformClient.getGenerateAsset({
          prompt: userPrompt,
          costInVC: 0,
          disableSuggestedOptimizations: false,
          jobId: uuidv4(),
          width: 512,
          height: 512,
          seed: seed,
          removeBackground: true,
        });
        bbaiStore.setEnemyAsset(enemyAssetTemp);
        bbaiStore.setPolling(true);
        if (regen) {
          return;
        }
        bbaiStore.setPromptStepState(bbaiPromptStepStatus.BACKGROUND);
        bbaiStore.setPolling(true);
      } else if (genType === bbaiPromptStepStatus.BACKGROUND) {
        const bgAssetTemp = await BBPlatformClient.getGenerateAsset({
          prompt: userPrompt,
          costInVC: 0,
          disableSuggestedOptimizations: false,
          jobId: uuidv4(),
          width: 512,
          height: 512,
          seed: seed,
          removeBackground: false,
        });
        bbaiStore.setBgAsset(bgAssetTemp);
        bbaiStore.setPromptStepState(bbaiPromptStepStatus.GENERATE_ASSETS);
        bbaiStore.setPolling(true);
      } else {
        throw Error("Incorrect Generation type selected");
      }
    } catch (error) {
      console.error(
        "Error generating assets",
        genType,
        (error as any).message,
        (error as any).stack
      );
      bbaiStore.loadingState(false);
      unauthorizedErrorHandler?.();
      bbaiStore.setErrorState({
        title: "Error generating assets",
        message: bbaiPromptOptions[bbaiStore.promptStepStatus].errPrompt,
      });
    }
    // console.log("we are now generating assets")
  };

  useEffect(() => {
    if (bbaiStore.promptStepStatus === bbaiPromptStepStatus.INITIAL) {
      if (bbaiStore.userCharPrompt === "") {
        return;
      }
      genJobAssets(bbaiStore.userCharPrompt, bbaiPromptStepStatus.CHARACTER);
    } else if (bbaiStore.promptStepStatus === bbaiPromptStepStatus.ENEMY) {
      if (bbaiStore.userEnemyPrompt === "") {
        return;
      }
      genJobAssets(bbaiStore.userEnemyPrompt, bbaiPromptStepStatus.ENEMY);
    } else if (bbaiStore.promptStepStatus === bbaiPromptStepStatus.BACKGROUND) {
      if (bbaiStore.userBgPrompt === "") {
        return;
      }
      genJobAssets(bbaiStore.userBgPrompt, bbaiPromptStepStatus.BACKGROUND);
    }

    // eslint-disable-next-line
  }, [
    bbaiStore.userCharPrompt,
    bbaiStore.userEnemyPrompt,
    bbaiStore.userBgPrompt,
  ]);

  const regenerateAssets = async () => {
    const indexNum = bbaiStore.messages.findIndex(
      ({ promptStage }) => promptStage === bbaiPromptStepStatus.GENERATE_ASSETS
    );

    bbaiStore.setMessage(indexNum);
    await genJobAssets(
      bbaiStore.userCharPrompt,
      bbaiPromptStepStatus.CHARACTER,
      true
    );
    await genJobAssets(
      bbaiStore.userEnemyPrompt,
      bbaiPromptStepStatus.ENEMY,
      true
    );
    await genJobAssets(
      bbaiStore.userBgPrompt,
      bbaiPromptStepStatus.BACKGROUND,
      true
    );
  };

  useEffect(() => {
    // console.log("isGuest changed: ", bbaiStore.isGuest);
    if (bbaiStore.isGuest === false && bbaiStore.openBit === true) {
      // console.log("here first use Effect");
      window.location.replace(
        `${Env.BB_DEEP_LINK}${
          bbaiStore.bbworldBBDocId ? bbaiStore.bbworldBBDocId : ""
        }`
      );
    }
    // eslint-disable-next-line
  }, [bbaiStore.isGuest, bbaiStore.openBit]);

  useEffect(() => {}, [height, width]);

  const formRender = useCallback(() => {
    const shouldPositionFlexEnd = isMobile && inputFocused && hasEmptyScrollSpace()
    const textInputDisabled = bbaiStore.promptStepStatus !== bbaiPromptStepStatus.INITIAL && bbaiStore.promptStepStatus !== bbaiPromptStepStatus.ENEMY && bbaiStore.promptStepStatus !== bbaiPromptStepStatus.BACKGROUND

    let inputSuffix:ReactElement|undefined
    if (bbaiStore.isLoading) {
      inputSuffix = <Spin indicator={antIcon} />
    }
    else if (textInputDisabled) {
      inputSuffix =
        <img
          className={classnames(styles.sendIcon,styles.disabled)}
          width={18}
          height={18}
          src="https://frontend-assets.buildbox.com/web-gen-ai/send_button.svg"
          alt="Send Button. Disabled because there is nothing to send at this time."
        />
    }
    else {
      inputSuffix =
        <img
          className={styles.sendIcon}
          width={18}
          height={18}
          onClick={handleSendClick}
          src="https://frontend-assets.buildbox.com/web-gen-ai/send_button.svg"
          alt="Send Button"
        />
    }

    return (
      <Form
        className={classnames({
          [styles.promptForm]: !isMobile,
          [styles.promptFormMobile]: isMobile,
        })}
      >
        <div className={styles.roboManContainer}>
          <img
            src="https://frontend-assets.buildbox.com/web-gen-ai/boxy_logo.webp"
            alt="robo"
            className={styles.roboMan}
          />
        </div>

        <div className={classnames(styles.promptFeedback, {[styles.justifyEnd]: shouldPositionFlexEnd})}>
          {bbaiStore.messages.map((msgData: PromptMessageProps, idx) => {
            return (
              <PromptMessage
                handleInputDisplay={handleInputDisplay}
                {...msgData}
                handleSelectedAsset={handleSelectedAsset}
                handleClick={handleClickDownloadBBDOC}
                key={idx}
                handleBuildRegenerateRestart={handleBuildRegenerateRestart}
                handleClaimLoginSkip={handleClaimLoginSkip}
                handleDoneTyping={scrollIntoView}
                bbdocId={bbaiStore.bbworldBBDocId}
              />
            );
          })}
          <div ref={bottomRef} />
        </div>

        <div className={styles.inputFieldWrapper}>
          <Input
            disabled={textInputDisabled || bbaiStore.isLoading}
            placeholder=""
            value={inputValue}
            suffix={inputSuffix}
            className={styles.inputField}
            onKeyDown={handleKeyDown}
            onChange={onChange}
            onFocus={()=>setInputFocused(true)}
            onBlur={()=>{
              setInputFocused(false)
              scrollIntoView()
            }}
            // autoFocus
            ref={inputRef}
          />
        </div>
      </Form>
    );
    // eslint-disable-next-line
  }, [
    bottomRef,
    bbaiStore.messages,
    inputValue,
    bbaiStore.isLoading,
    displayFinalOptions,
    bbaiStore.bbworldBBDocId,
    isMobile,
    inputFocused
    // agregatedProgress,
  ]);

  console.debug("[PlaceholderFormComponent].render]", "isGuest:", bbaiStore.isGuest, "openBit:", bbaiStore.openBit);
  return (
    <>
      {contextHolder}
      {bbaiStore.error !== undefined && openNotificationWithIcon()}
      <Row className={styles.container}>
        {!isMobile && <Col xs={24} md={7}></Col>}
        <Col className={styles.container} xs={24} md={10}>
          <div className={styles.promptFormContainer}>
            <NavButtons />
            {formRender()}
          </div>
        </Col>
        {!isMobile && (
          <Col className={styles.container} xs={24} md={7}>
            <div className={styles.dispText}>
              <h1>AI for Dreamers</h1>
              <p>Make Games, Make Money Using AI</p>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}
