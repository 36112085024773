import { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "../styles/PromptMessage.module.css";
import { Row, Col } from "antd";

import {
  AssetType,
  bbaiPromptOptions,
  bbaiPromptStepStatus,
} from "../constants/bbai.prompts";
import { FileType } from "./DownloadLink";
import FinalOptions, { FinalOptionsType } from "./FinalOptions";
import ClaimLoginSkipOptions, {
  ClaimLoginSkipOptionsType,
} from "./ClaimLoginSkipOptions";
import CompletedUpload from "./CompletedDownload";
import DownloadOptions from "./DownloadOptions";
import useIsMobile from "../hooks/useIsMobile";
// import CircularProgress from "./CircularProgress";
import useBBAIStore from "../store";
import GenerationProgressReport from "./GenerationProgressReport";

// FIXME: texty isn't wokring maybe a better antiomation library then this small antd item
export interface PromptMessageProps {
  message: string;
  timestamp: string;
  user: {
    id: string;
    pic: string;
  };
  images?: string[];
  handleSelectedAsset?: (img: string, assetType: string, idx: number) => void;
  assetType?: AssetType | bbaiPromptStepStatus;
  promptStage: bbaiPromptStepStatus;
  handleClick?: (fileType: FileType) => void;
  handleBuildRegenerateRestart?: (opType: FinalOptionsType) => void;
  handleClaimLoginSkip?: (opType: ClaimLoginSkipOptionsType) => void;
  handleInputDisplay?: (renderFinalOptions: boolean) => void;
  handleDoneTyping?: () => void;
  bbdocId?: string;
  agregateProgress?: number;
}

const PromptMessage: FunctionComponent<PromptMessageProps> = ({
  message = "",
  timestamp,
  user,
  images,
  assetType = "",
  handleSelectedAsset,
  promptStage,
  handleClick,
  handleBuildRegenerateRestart,
  handleClaimLoginSkip,
  handleInputDisplay,
  handleDoneTyping,
  bbdocId = "",
}) => {
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [renderFinalOptions, setRenderFinalOptions] = useState<boolean>(false);
  const [useLandscapeImage, setUseLandscapeImage] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const bbaiStore = useBBAIStore();
  const [messageText, setMessageText] = useState<string>("");
  const [typing, setTyping] = useState<boolean>(false);
  const indexRef = useRef(0);
  const intervalRef = useRef<any>();

  // let timeoutIdRef = useRef<null|NodeJS.Timeout>(null)

  useEffect(() => {
    if (message === bbaiPromptOptions["BUILD_REGERNATE_RESTART"].prompt) {
      setRenderFinalOptions(true);
    } else if (message === bbaiPromptOptions["SELECT_BACKGROUND"].prompt) {
      setUseLandscapeImage(true);
    }
  }, [message]);

  useEffect(() => {
    handleInputDisplay?.(renderFinalOptions);
  }, [renderFinalOptions, handleInputDisplay]);

  const updateMessageText = () => {
    const currentText = message[indexRef.current]
      ? message[indexRef.current]
      : "";

    setMessageText((prev) => prev + currentText);
  };

  useEffect(() => {
    indexRef.current = 0;

    if (
      user.id === "1" &&
      promptStage === bbaiStore.promptStepStatus &&
      indexRef.current === 0
    ) {
      setTyping(true);
      intervalRef.current = setInterval(() => {
        updateMessageText();

        if (indexRef.current >= message.length) {
          clearInterval(intervalRef.current);
          indexRef.current = 0;
          setTyping(false);
          // wait a tick, then fire the event
          requestAnimationFrame(() => handleDoneTyping?.())
        }
      }, 50); // adjust speed here

      return () => {
        clearInterval(intervalRef.current);
        indexRef.current = 0;
        setMessageText("");
      }; // cleanup on unmount
      // setMessageText(message);
    } else {
      setMessageText(message);
    }
    // eslint-disable-next-line
  }, [message, user]);

  useEffect(() => {
    if (
      user.id === "1" &&
      promptStage === bbaiStore.promptStepStatus &&
      ((messageText.length === 0 && indexRef.current === 1) ||
        (messageText.length === 0 && indexRef.current === 0))
    ) {
      indexRef.current = 0;
      setMessageText("");
    } else {
      indexRef.current += 1;
    }
    // eslint-disable-next-line
  }, [messageText, user]);

  const handleImageClick = (img: string, assetType: string, idx: number) => {
    // don't allow selection to be changed
    if (!bbaiStore.completed) {
      setSelectedImages([img]);
      handleSelectedAsset?.(img, assetType, idx);
    }
  };

  const checkProgress = () => {
    return (
      promptStage === bbaiPromptStepStatus.CHAR_PROGRESS ||
      promptStage === bbaiPromptStepStatus.ENEMY_PROGRESS ||
      promptStage === bbaiPromptStepStatus.BACKGROUND_PROGRESS
    );
  };

  return (
    <>
      <Row
        className={`${styles.promptMessage} ${
          user.id === "1"
            ? styles.promptFeedbackBBAI
            : styles.promptFeedbackUser
        }`}
      >
        <Col xs={4} sm={3} md={3} lg={2} xl={2}>
          <img className={styles.senderImg} src={user.pic} alt="sender" />
        </Col>
        <Col xs={20} sm={21} md={21} lg={22} xl={22}>
          {!checkProgress() && !renderFinalOptions && messageText}
          {renderFinalOptions && (
            <FinalOptions handleClick={handleBuildRegenerateRestart} />
          )}
          {promptStage === bbaiPromptStepStatus.CLAIM_LOGIN_SKIP && (
            <ClaimLoginSkipOptions handleClick={handleClaimLoginSkip} />
          )}
          {
            <div className={styles.promptImgContainer}>
              {promptStage === bbaiPromptStepStatus.SELECT_ASSETS &&
                images?.map((img, idx) => {
                  //FIXME: come up with a better method here.
                  if (idx >= 4) {
                    return null;
                  }
                  return typing ? undefined : (
                    <img
                      onClick={() => handleImageClick(img, assetType, idx)}
                      key={idx}
                      className={`${styles.promptImg} ${
                        selectedImages.includes(img) ||
                        (idx === 0 && selectedImages.length === 0)
                          ? styles.promptImgActive
                          : ""
                      } ${useLandscapeImage ? styles.promptImgLandscape : ""}`}
                      src={img}
                      alt="prompt-img"
                      loading="lazy"
                    />
                  );
                })}
            </div>
          }
          {checkProgress() && (
            <GenerationProgressReport promptStage={promptStage} />
          )}
        </Col>
        {promptStage === bbaiPromptStepStatus.COMPLETE && (
          <Col span={24} className={styles.promptDownload}>
            {!isMobile && <CompletedUpload />}
            {!typing && (
              <DownloadOptions handleClick={handleClick} bbdocBitId={bbdocId} />
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default PromptMessage;
