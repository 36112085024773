import { BBPlatformClient, BBPlatformClientNotAuthorizedError } from "./api/BBPlatform"
import { Env } from "./Env"

const SHOULD_REFRESH_KEY = "DEBUG_bbai-should-refresh"

export class DebugMode {
  updateInterval: number|null = 5000

  constructor() {
    const debugButtonContainer = document.createElement("div")
    const styles = debugButtonContainer.style
    styles.zIndex = "11"
    styles.position = "fixed"
    styles.top = "4%"
    styles.left = "0"
    styles.width = "30%"
    styles.backgroundColor = "rgba(255, 255, 255, 0.2)"
    const storedRefreshChecked = localStorage !== null && localStorage.getItem(SHOULD_REFRESH_KEY) === "true" ? "checked" : ""
    debugButtonContainer.innerHTML = `
    <button id="debugMode-clear-prompt-state-button" style="display:block;" type="button">clear prompt state</button>
    <button id="debugMode-logout-button" style="display:block;" type="button">Log-out</button>
    <label style="background-color: grey;" for="shouldRefreshCheckbox">refresh after?</label>
    <input id="debugMode-refresh-checkbox" name="shouldRefreshCheckbox" type="checkbox" ${storedRefreshChecked} />
    <div id="debugMode-user-info"></div>
    <button style="display: block;" type="button" id="debugMode-update-user-info-button">Update User Info</button>
    <label style="background-color: grey;" for="autoUpdateUser">auto update user info?</label>
    <input id="debugMode-auto-update-user" name="autoUpdateUser" type="checkbox" />
  `
    document.body.appendChild(debugButtonContainer)

    const pushReactDevServerOverlayDown = document.createElement("style")
    pushReactDevServerOverlayDown.innerText = `
    #webpack-dev-server-client-overlay {
      z-index: 2 !important;
    }
  `
    document.head.appendChild(pushReactDevServerOverlayDown)

    this.clearPromptState = this.clearPromptState.bind(this);
    this.handleRefreshCheck = this.handleRefreshCheck.bind(this);
    this.bbAuthLogout = this.bbAuthLogout.bind(this);
    this.refreshUserInfo = this.refreshUserInfo.bind(this);
    this.handleAutoUpdateChanged = this.handleAutoUpdateChanged.bind(this);
    // debugButtonContainer.addEventListener("load", () => {
      (document.getElementById("debugMode-clear-prompt-state-button") as HTMLElement).addEventListener("click", this.clearPromptState);
      (document.getElementById("debugMode-refresh-checkbox") as HTMLElement).addEventListener("click", this.handleRefreshCheck);
      (document.getElementById("debugMode-logout-button") as HTMLElement).addEventListener("click", this.bbAuthLogout);
      (document.getElementById("debugMode-update-user-info-button") as HTMLElement).addEventListener("click", this.refreshUserInfo);
      (document.getElementById("debugMode-auto-update-user") as HTMLInputElement).addEventListener("click", this.handleAutoUpdateChanged);
    // })

    this.refreshUserInfo()

    // setup update loop
    this.update = this.update.bind(this) // bind update to this so it can be called inside a setTimeout context
    this.update()
  }

  get refreshChecked():boolean {
    const ele = document.getElementById("debugMode-refresh-checkbox") as HTMLInputElement|null
    const checked = ele !== null && ele.checked
    return checked
  }

  get autoUpdateChecked():boolean {
    const ele = document.getElementById("debugMode-auto-update-user") as HTMLInputElement|null
    const checked = ele !== null && ele.checked
    return checked
  }

  refresh(delay:number=1000):void {
    if (this.refreshChecked) {
      console.log(`will refresh after ${delay} ms`)
      setTimeout(() => window.location.reload(), delay)
    }
  }

  handleRefreshCheck():void {
    if (localStorage === null) {
      return
    }
    if (this.refreshChecked) {
      localStorage.setItem(SHOULD_REFRESH_KEY, "true")
    }
    else {
      global.localStorage.removeItem(SHOULD_REFRESH_KEY)
    }
  }

  clearPromptState():void {
    console.log(`removing local storage bbai-storage`)
    localStorage.removeItem("bbai-storage")
    this.refresh()
  }

  bbAuthLogout():void {
    const root = document.getElementById("root") as HTMLDivElement
    const frame = root.getElementsByTagName("iframe")[0];
    const {protocol, hostname, port} = new URL(frame.src);
    frame.src = `${protocol}//${hostname}:${port}/logout-redirect`;
    (frame.parentElement as HTMLDivElement).style.display = "initial"
    this.refresh()
  }

  handleAutoUpdateChanged():void {
    if (this.autoUpdateChecked) {
      this.refreshUserInfo()
    }
  }

  async refreshUserInfo():Promise<void> {
    const container = document.getElementById("debugMode-user-info")
    if (container === null) {
      console.debug(`[DebugMode].refreshUserInfo] container is null`)
      return
    }

    container.innerHTML = "updating..."
    const updateButton = document.getElementById("debugMode-update-user-info-button") as HTMLButtonElement
    updateButton.disabled = true
    const ogUpdateButton = updateButton.innerHTML
    updateButton.innerHTML = "updating..."
    try {
      const userInfo = await BBPlatformClient.login()
      const email = userInfo.user.Email
      container.innerHTML = `${email}`
    }
    catch (e) {
      if (e instanceof BBPlatformClientNotAuthorizedError) {
        container.innerHTML = "Not logged in"
      }
      else {
        container.innerHTML = `<span class='debugMode-error'>${(e as any).message}</span>`
      }
    }
    updateButton.innerHTML = ogUpdateButton
    updateButton.disabled = false
  }

  async update():Promise<void> {
    if (this.autoUpdateChecked) {
      await this.refreshUserInfo()
    }

    if (this.updateInterval !== null && !isNaN(this.updateInterval)) {
      setTimeout(this.update, this.updateInterval)
    }
  }
}

if (Env.DEBUG_MODE) {
  (window as any).debugModeUI = new DebugMode();
  (window as any).BBPlatformClient = BBPlatformClient;
}
