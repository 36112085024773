export const bbaiUser = {
  id: "1",
  pic: "https://frontend-assets.buildbox.com/web-gen-ai/boxy_avatar.svg",
};

export const defaultUser = {
  id: "2",
  pic: "https://frontend-assets.buildbox.com/web-gen-ai/user_avatar.svg",
};

export enum bbaiPromptStepStatus {
  INITIAL = "INITIAL",
  CHARACTER = "CHARACTER",
  ENEMY = "ENEMY",
  BACKGROUND = "BACKGROUND",
  GENERATE_ASSETS = "GENERATE_ASSETS",
  SELECT_ASSETS = "SELECT_ASSETS",
  GENERATE_GAME = "GENERATE_GAME",
  SELECT_CHARACTER = "SELECT_CHARACTER",
  SELECT_ENEMY = "SELECT_ENEMY",
  SELECT_BACKGROUND = "SELECT_BACKGROUND",
  BUILD_REGERNATE_RESTART = "BUILD_REGERNATE_RESTART",
  BUILD_BBDOC = "BUILD_BBDOC",
  DOWNLOAD_BBDOC = "DOWNLOAD_BBDOC",
  CLAIM_ACCOUNT = "CLAIM_ACCOUNT",
  COMPLETE = "COMPLETE",
  CLAIM_LOGIN_SKIP = "CLAIM_LOGIN_SKIP",
  LOGIN_ACCOUNT = "LOGIN_ACCOUNT",
  DONE = "DONE",
  CHAR_PROGRESS = "CHAR_PROGRESS",
  ENEMY_PROGRESS = "ENEMY_PROGRESS",
  BACKGROUND_PROGRESS = "BACKGROUND_PROGRESS",
}

export const bbaiPromptOptions: Record<
  string,
  { prompt: string; errPrompt: string }
> = {
  INITIAL: {
    prompt:
      "Hello, I'm BBAI, and I'm here to help you make your first game! What do you want your main character to look like?",
    errPrompt:
      "I'm sorry, I didn't quite get that. Could you tell me more about your character?",
  },
  CHARACTER: {
    prompt: "What do you want your main character to look like?",
    errPrompt:
      "I'm sorry, I didn't quite get that. Could you tell me more about your character?",
  },
  ENEMY: {
    prompt: "What sort of enemies or obstacles do they have to defeat?",
    errPrompt:
      "I'm sorry, I didn't quite get that. Could you tell me more about the obstacles?",
  },
  BACKGROUND: {
    prompt: "Tell me where your game takes place.",
    errPrompt:
      "I'm sorry, I didn't quite get that. Could you tell me more about the background?",
  },
  GENERATE_ASSETS: {
    prompt: "Great! Generating your game assets now...",
    errPrompt:
      "I'm sorry, I was unable to generate your assets (something went wrong). Please try again.",
  },
  SELECT_CHARACTER: {
    prompt: "Please choose your unbeatable main character.",
    errPrompt:
      "I'm sorry, I didn't quite get that. Please choose your unbeatable main character:",
  },
  SELECT_ENEMY: {
    prompt: "Please choose your terrifying enemy.",
    errPrompt: "I'm sorry, I didn't quite get that. Please select your enemy:",
  },
  SELECT_BACKGROUND: {
    prompt: "Please choose your game's setting.",
    errPrompt:
      "I'm sorry, I didn't quite get that. Please select your background:",
  },
  BUILD_REGERNATE_RESTART: {
    prompt:
      "I can now either BUILD your game, REGENERATE your assets, or RESTART.",
    errPrompt:
      "Error: I'm sorry, I didn't quite get that. Please select BUILD, REGENERATE, or RESTART.",
  },
  CLAIM_LOGIN_SKIP: {
    prompt: "",
    errPrompt:
      "Error: I'm sorry, I didn't quite get that. Please select CLAIM, LOGIN, or SKIP.",
  },
  BUILD_BBDOC: {
    prompt: "Building BBDOC game template one moment please!",
    errPrompt: "Error while trying to download BBDoc",
  },
  COMPLETE: {
    prompt:
      "You can also download your game project as well as Buildbox to start modding it!",
    errPrompt: "Error while trying to download BBDoc",
  },
  COMPLETE_MOBILE: {
    prompt:
      "You can also download your game project as well as Buildbox to start modding it!",
    errPrompt: "something went wrong when completing the game",
  },
  DOWNLOAD_BBDOC: {
    prompt:
      "I have finished uploading your game and it is now ready to play on the Buildbox World app!",
    errPrompt: "Error while trying to download BBDoc",
  },
  CLAIM_ACCOUNT: {
    prompt:
      "I have finished uploading your game and it is now ready to play on the Buildbox World app!",
    errPrompt:
      "We were unable to claim your account and bbdoc, try skipping and manually claiming your game!",
  },
  CHAR_PROGRESS: {
    prompt: "Got it!  Generating your main character now…",
    errPrompt: "Error while trying to generate character",
  },
  ENEMY_PROGRESS: {
    prompt: "Great! Generating main character foils now…",
    errPrompt: "Error while trying to generate enemy",
  },
  BACKGROUND_PROGRESS: {
    prompt: "Cool! Generating game location now…",
    errPrompt: "Error while trying to generate background",
  },
};

export enum AssetType {
  CHARACTER = "CHARACTER",
  ENEMY = "ENEMY",
  BACKGROUND = "BACKGROUND",
}
