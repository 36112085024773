import { sendCustomEvent } from "../api/GoogleAnalyticsWrapper";
import useIsMobile from "../hooks/useIsMobile";
import AnimatedButton from "./AnimatedButton";
import DownloadLink, { FileType } from "./DownloadLink";
// import {isMobile} from 'react-device-detect';
// import Env from "../Env";

export interface Props {
  handleClick?: (fileType: FileType) => void;
  bbdocBitId?: string;
}
const DownloadOptions = ({ bbdocBitId, handleClick }: Props) => {
  const mobileRender = () => {
    return (
      <div>
        <AnimatedButton text={"Play Bit!"} handleClick={playBit} />
      </div>
    );
  };

  const desktopRender = () => {
    return (
      <ol>
        <li>
          Download and install Buildbox Classic:
          <br />{" "}
          <DownloadLink
            handleClick={handleClick}
            fileType={FileType.BBCLASSIC_INSTALLER}
          />
        </li>
        <li>
          Download your project file:
          <br />{" "}
          <DownloadLink handleClick={handleClick} fileType={FileType.BBDOC} />
        </li>
        <li>Double click on project file to load in Buildbox. You're ready!</li>
      </ol>
    );
  };
  const playBit = () => {
    sendCustomEvent("play_bit")
    handleClick?.(FileType.BBDOC);
    // window.location.replace( `${Env.BB_DEEP_LINK}${bbdocBitId ? bbdocBitId : ""}`);
  };
  const isMobile = useIsMobile();

  return <>{isMobile ? mobileRender() : desktopRender()}</>;
};

export default DownloadOptions;
