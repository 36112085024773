import React, { FunctionComponent, useState } from 'react';
import { Button } from 'antd';
import styles from '../styles/AnimatedButton.module.css';

export interface AnimatedButtonProps {
    handleClick?: () => void;
    text: string;
}

const AnimatedButton: FunctionComponent<AnimatedButtonProps> = ({text, handleClick}) => {
  const [isAnimating] = useState(true);
  const [buttonRadius] = useState(100);

  return (
    <Button
      className={isAnimating ? `${styles.animatedButton}` : ''}
      shape="circle"
      style={{ width: buttonRadius, height: buttonRadius }}
      onClick={handleClick}
      type="primary"
    >
      {text}
    </Button>
  );
};

export default AnimatedButton;
