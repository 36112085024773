import { QRCode } from "antd";
import { FunctionComponent } from "react";
import styles from "../styles/CompletedUpload.module.css";

interface CompletedUploadProps {

}

const CompletedUpload: FunctionComponent<CompletedUploadProps> = () => {
    return (
        <div className={styles.completedUploadWrapper}>
            <div className={styles.qrWrapper}>
                <QRCode size={100} bgColor="#fff" value="https://buildboxworld.com" />
            </div>
            <div className={styles.poweredBy}>
                <div className={styles.poweredByContent}><span className={styles.poweredByText}>Powered by </span><img width={38} src="https://frontend-assets.buildbox.com/web-gen-ai/BBWorld_Logo.svg" alt="bbworld" /><span className={styles.poweredByTextTwo}>BUILDBOX</span></div>
                <p className={styles.poweredByTextThree}>WORLD &#174;</p>
                <p className={styles.poweredByLearnMore}>Learn more at <a target="_blank" rel="noreferrer" href="https://buildboxworld.com">buildboxworld.com</a></p>
            </div>
        </div>
    );
}

export default CompletedUpload;