import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import { GenProgressProps } from "./components/GenerationProgressReport";
import { PromptMessageProps } from "./components/PromptMessage";
import {
  AssetType,
  bbaiPromptOptions,
  bbaiPromptStepStatus,
  bbaiUser,
} from "./constants/bbai.prompts";
import { Txt2ImgGenerationJobCreationResultDTO } from "./constants/GenPrompTypes";

// Bump this to clear anybody's store that is older than this
const BBAI_STORE_VERSION = 2.2;
const BBAI_STORAGE_KEY = "bbai-storage";
const BBAI_STORE_VERSION_KEY = `${BBAI_STORAGE_KEY}-version`;

// clear out older versions of this app / storage schema
const storedVersionStr = localStorage.getItem(BBAI_STORE_VERSION_KEY);
const storedVersion: number =
  storedVersionStr !== null ? parseInt(storedVersionStr) : 0;
if (isNaN(storedVersion) || storedVersion < BBAI_STORE_VERSION) {
  console.warn(
    `[store.ts] Detected older version of prompt storage schema - clearing existing state`
  );
  localStorage.removeItem(BBAI_STORAGE_KEY);
  localStorage.setItem(BBAI_STORE_VERSION_KEY, BBAI_STORE_VERSION.toString(10));
} else {
  console.debug(
    `[store.ts] bbai-storage-version is valid. found: ${storedVersion}, minimum: ${BBAI_STORE_VERSION}`
  );
}

interface errorBody {
  title: string;
  message: string;
}

export type State = {
  version: number;
  messages: PromptMessageProps[];
  isLoading: boolean;
  completed?: boolean;
  error?: errorBody;
  polling: boolean;
  getAsset?: boolean;
  downloadReady: boolean;
  promptStepStatus: bbaiPromptStepStatus;
  userCharPrompt: string;
  userEnemyPrompt: string;
  userBgPrompt: string;
  userCharSelectedImage: string;
  charImageIdx: number;
  userEnemySelectedImage: string;
  enemyImageIdx: number;
  userBgSelectedImage: string;
  bgImageIdx: number;
  charAsset: Txt2ImgGenerationJobCreationResultDTO | null;
  enemyAsset: Txt2ImgGenerationJobCreationResultDTO | null;
  bgAsset: Txt2ImgGenerationJobCreationResultDTO | null;
  bbworldBBDocId: string;
  eta: number;
  queue: number;
  isGuest: boolean;
  openBit: boolean;
  generationProgress: GenProgressProps;
};

export type Action = {
  addPrompt: (payload: PromptMessageProps) => void;
  completedPrompt: (payload: PromptMessageProps) => void;
  loadingState: (loading: boolean) => void;
  setGenerateAssets: (genAssets: boolean) => void;
  setPolling: (polling: boolean) => void;
  downloadTemplate: (download: boolean) => void;
  initial: () => void;
  selectAssets: () => void;
  setErrorState: (error: errorBody | undefined) => void;
  setPromptStepState: (stepStatus: bbaiPromptStepStatus) => void;
  setUserCharPrompt: (prompt: string) => void;
  setUserEnemyPrompt: (prompt: string) => void;
  setUserBgPrompt: (prompt: string) => void;
  setUserCharSelectedImage: (url: string, idx: number) => void;
  setUserEnemySelectedImage: (url: string, idx: number) => void;
  setUserBgSelectedImage: (url: string, idx: number) => void;
  setCharAsset: (asset: Txt2ImgGenerationJobCreationResultDTO | null) => void;
  setEnemyAsset: (asset: Txt2ImgGenerationJobCreationResultDTO | null) => void;
  setBgAsset: (asset: Txt2ImgGenerationJobCreationResultDTO | null) => void;
  setBBWorldBBDocId: (id: string) => void;
  handleSelectPrompt: (
    assetType: AssetType | bbaiPromptStepStatus,
    images: string[]
  ) => void;
  handleRestartRegenerate: (stepState: bbaiPromptStepStatus) => void;
  setMessage: (messageNum: number) => void;
  setEta: (eta: number, queue: number) => void;
  setGuest: (isGuest: boolean) => void;
  setOpenBit: (openBit: boolean) => void;
  setGenerationProgress: (progress: GenProgressProps) => void;
};

export const initialBBAIState: State = {
  version: BBAI_STORE_VERSION,
  isLoading: false,
  error: undefined,
  messages: [
    {
      message: bbaiPromptOptions["INITIAL"].prompt,
      timestamp: Date.now().toString(),
      user: bbaiUser,
      promptStage: bbaiPromptStepStatus.INITIAL,
    },
  ],
  polling: false,
  downloadReady: false,
  promptStepStatus: bbaiPromptStepStatus.INITIAL,
  userCharPrompt: "",
  userEnemyPrompt: "",
  userBgPrompt: "",
  userCharSelectedImage: "",
  userEnemySelectedImage: "",
  userBgSelectedImage: "",
  charAsset: null,
  enemyAsset: null,
  bgAsset: null,
  bbworldBBDocId: "",
  completed: false,
  charImageIdx: 0,
  enemyImageIdx: 0,
  bgImageIdx: 0,
  eta: -1,
  queue: -1,
  isGuest: true,
  openBit: false,
  generationProgress: {
    charProgress: 0,
    enemyProgress: 0,
    bgProgress: 0,
    charProgressQueue: null,
    enemyProgressQueue: null,
    bgProgressQueue: null,
  },
};

//TODO: too many repeated function, refactor
const useBBAIStore = create<State & Action>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialBBAIState,
        addPrompt: (payload: PromptMessageProps) =>
          set((state) => ({
            ...state,
            isLoading: false,
            messages: [...state.messages, payload],
          })),
        completedPrompt: (payload: PromptMessageProps) =>
          set((state) => ({
            ...state,
            isLoading: false,
            completed: true,
            messages: [...state.messages, payload],
          })),
        loadingState: (loading: boolean) =>
          set((state) => ({
            ...state,
            isLoading: loading,
          })),
        setGenerateAssets: (genAssets: boolean) =>
          set((state) => ({
            ...state,
            getAsset: genAssets,
            isLoading: true,
          })),
        setPolling: (polling: boolean) =>
          set((state) => ({
            ...state,
            isLoading: true,
            polling: polling,
          })),
        downloadTemplate: (download: boolean) =>
          set((state) => ({
            ...state,
            downloadReady: download,
          })),
        initial: () => set({ ...initialBBAIState }),
        selectAssets: () =>
          set((state) => ({
            ...state,
            isLoading: true,
          })),
        setPromptStepState: (stepStatus: bbaiPromptStepStatus) =>
          set((state) => ({
            ...state,
            promptStepStatus: stepStatus,
          })),
        setUserCharPrompt: (prompt: string) =>
          set((state) => ({
            ...state,
            userCharPrompt: prompt,
          })),
        setUserBgPrompt: (prompt: string) =>
          set((state) => ({
            ...state,
            userBgPrompt: prompt,
          })),
        setUserEnemyPrompt: (prompt: string) =>
          set((state) => ({
            ...state,
            userEnemyPrompt: prompt,
          })),
        setUserCharSelectedImage: (url: string, idx: number) =>
          set((state) => ({
            ...state,
            userCharSelectedImage: url,
          })),
        setUserBgSelectedImage: (url: string, idx: number) =>
          set((state) => ({
            ...state,
            userBgSelectedImage: url,
          })),
        setUserEnemySelectedImage: (url: string, idx: number) =>
          set((state) => ({
            ...state,
            userEnemySelectedImage: url,
          })),
        setErrorState: (error: errorBody | undefined) =>
          set((state) => ({
            ...state,
            error: error,
          })),
        setCharAsset: (asset: Txt2ImgGenerationJobCreationResultDTO | null) =>
          set((state) => ({
            ...state,
            charAsset: asset,
          })),
        setBgAsset: (asset: Txt2ImgGenerationJobCreationResultDTO | null) =>
          set((state) => ({
            ...state,
            bgAsset: asset,
          })),
        setEnemyAsset: (asset: Txt2ImgGenerationJobCreationResultDTO | null) =>
          set((state) => ({
            ...state,
            enemyAsset: asset,
          })),
        setBBWorldBBDocId: (id) =>
          set((state) => ({
            ...state,
            bbworldBBDocId: id,
          })),
        handleSelectPrompt: (
          assetTypeSearch: AssetType | bbaiPromptStepStatus,
          images: string[]
        ) => {
          const messageIdx = get().messages.findIndex(
            ({ assetType }) => assetType === assetTypeSearch
          );
          let newMessages = [...get().messages];
          if (messageIdx === -1) {
            newMessages = [
              ...get().messages,
              {
                message: bbaiPromptOptions[assetTypeSearch].prompt,
                timestamp: Date.now().toString(),
                user: bbaiUser,
                promptStage: bbaiPromptStepStatus.SELECT_ASSETS,
                images: images,
                assetType: assetTypeSearch,
              },
            ];
          } else {
            newMessages[messageIdx] = {
              message: bbaiPromptOptions[assetTypeSearch].prompt,
              timestamp: Date.now().toString(),
              user: bbaiUser,
              promptStage: bbaiPromptStepStatus.SELECT_ASSETS,
              images: images,
              assetType: assetTypeSearch,
            };
          }
          set((state) => ({
            ...state,
            messages: newMessages,
          }));
        },
        handleRestartRegenerate: (stepState: bbaiPromptStepStatus) =>
          set((state) => ({
            ...state,
            charAsset: null,
            enemyAsset: null,
            bgAsset: null,
            promptStepStatus: stepState,
          })),
        setMessage: (messageNum: number) =>
          set((state) => ({
            ...state,
            messages: state.messages.slice(0, messageNum),
          })),
        setEta: (eta: number, queue: number) =>
          set((state) => ({
            ...state,
            eta: eta,
            queue: queue,
          })),
        setGuest: (isGuest: boolean) =>
          set((state) => ({
            ...state,
            isGuest: isGuest,
          })),
        setOpenBit: (openBit: boolean) =>
          set((state) => ({ ...state, openBit })),
        setGenerationProgress: (progress: GenProgressProps) =>
          set((state) => ({ ...state, generationProgress: { ...progress } })),
      }),
      {
        name: "bbai-storage", // unique name
        storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      }
    )
  )
);

export default useBBAIStore;
