import { AuthResult } from "bb-auth-frame/component";
import {
  BBDownloadVersion,
  CreateTxt2ImgGenerationJobDTO,
  DownloadPlatforms,
  ImageGenerationJobCreationResultDTO,
  templateType,
  Txt2ImgGenerationJobCreationResultDTO,
} from "../constants/GenPrompTypes";
import Env from "../Env";

export interface BBPlatformResponse<T> {
  metadata: any; // TODO - fill this out
  payload: T;
}

export class BBPlatformClientError extends Error {}

/**
 * Indicates that validation failed for our request
 */
export class BBPlatform4XXError extends Error {
  constructor(
    readonly url: string,
    readonly status: number,
    readonly metadata: any
  ) {
    super(`Received ${status} from ${url}`);
  }
}

/**
 * Indicates that either the user couldn't be authenticated or is not authorized
 */
export class BBPlatformClientNotAuthorizedError extends Error {}

/**
 * A simple extending class of BBPlatform4XXError - simply indicates that 500 error was recieved
 */
export class BBPlatformServerError extends BBPlatform4XXError {}

export interface RequestProps {
  url: string;
  headers?: {};
  body?: {};
  publicEndpoint?: boolean;
  isAuthRetry?: boolean;
}

export type LoginPayload = {
  user: {
    Id: string;
    Email: string;
    ReferralInfo: any;
  };
  userCreated: boolean;
};

export interface TimestampedTokens extends AuthResult {
  timestamp: number;
}

/** ************************************************************************************************
 * Wrapper class for BBPlatform service endpoints
 *
 * This class is designed to be a static-only/singleton class to avoid needing
 * to pass it down the react component tree
 * and avoid needint to put it in some kind of data store
 * We can revisit this if the singleton pattern presents issues
 ************************************************************************************************* */
export class BBPlatformClient {
  static tokens: TimestampedTokens | null = null;

  /* ***************************************************************************
   * generic platform-API functions below
   ************************************************************************** */
  static setTokens(tokens: (AuthResult & { timestamp: number }) | null): void {
    if (tokens !== null) {
      BBPlatformClient.tokens = tokens;
    } else {
      BBPlatformClient.tokens = null;
    }
  }

  static getUnexpiredTokens(): TimestampedTokens | null {
    if (BBPlatformClient.isBBWorldMode()) {
      let injectedToken: string | undefined | null;
      if (BBPlatformClient.isAndroidJSInterface()) {
        // Android BBWorld has a magic javascript injector that creates this function
        // typescript will never be happy about it because it doesn't know
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        injectedToken = Android.fetchBBToken();
        console.log("Android token injection got token: ", injectedToken);
      } else {
        injectedToken = (window as any).BBWorldAccessToken;
        console.log(
          "Android token injection not available; using window.BBWorldAccessToken: ",
          injectedToken
        );
      }

      if (typeof injectedToken === "string") {
        return {
          timestamp: Date.now() + 24 * 60 * 60 * 1000,
          AccessToken: injectedToken,
        } as TimestampedTokens; // TODO - fix the AuthResult definition in bb-auth-frame to allow for nulls on Refresh and Id tokens
      } else {
        console.error(
          `[BBPlatformClient].getUnexpiredTokens] in bbworld mode, but BBWorldAccessToken tokens not found`
        );
        return null;
      }
    }
    if (
      BBPlatformClient.tokens !== null &&
      Date.now() - BBPlatformClient.tokens.timestamp < Env.ACCESS_TIMEOUT_MS
    ) {
      return BBPlatformClient.tokens;
    } else {
      return null;
    }
  }

  static isBBWorldMode(): boolean {
    const bbworldModeValue = new URLSearchParams(
      (window as any).location.search
    ).get("bbworld_mode");
    return (
      bbworldModeValue !== null && bbworldModeValue.toLowerCase() === "true"
    );
  }

  // AP: can't be named "useAndroidJSInterface" like the param because React (or eslint?) will think it's a hook
  static isAndroidJSInterface(): boolean {
    const androidJSInterfaceValue = new URLSearchParams(
      (window as any).location.search
    ).get("use_android_js_interface");
    return (
      androidJSInterfaceValue !== null &&
      androidJSInterfaceValue.toLowerCase() === "true"
    );
  }

  static async apiRequest<T = any>(
    method: "get" | "post" | "put",
    props: RequestProps
  ): Promise<BBPlatformResponse<T>> {
    const {
      url,
      headers: providedHeaders = {
        "Content-Type": "application/json",
        "webgenai-client-version": true,
      },
      body: bodyJSON,
      publicEndpoint = false,
      isAuthRetry = false,
    } = props;
    let headers = providedHeaders;
    if (!publicEndpoint) {
      const tokens = BBPlatformClient.getUnexpiredTokens();
      if (tokens !== null) {
        headers = { ...headers, Token: tokens.AccessToken };
      } else {
        throw new BBPlatformClientNotAuthorizedError();
      }
    }
    const body = bodyJSON !== undefined ? JSON.stringify(bodyJSON) : undefined;

    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    const responseBody = await response.json();
    // TODO - handle network or parse errors via catch?

    if (response.status >= 200 && response.status < 300) {
      // a wee bit of validation before we commit this "any" to TS land
      if (
        typeof responseBody.payload !== "object" ||
        typeof responseBody.metadata !== "object"
      ) {
        const message = `Unexpected response body format from ${method} ${url}.`;
        console.error(message);
        console.error(responseBody);
        throw new BBPlatformClientError(message);
      }

      return responseBody;
    } else if (response.status === 403 || response.status === 401) {
      BBPlatformClient.tokens = null;
      if (!isAuthRetry) {
        // try again - nulling out the accessToken should trigger a token refresh if possible
        // make sure isAuthRetry is true so we don't end up in an endless loop
        return await BBPlatformClient.apiRequest(method, {
          ...props,
          isAuthRetry: true,
        });
      } else {
        console.error(
          `[BBPlatformClient] not authorized: ${url} ${response.status} ${responseBody.metadata}`
        );
        throw new BBPlatformClientNotAuthorizedError();
      }
    } else if (response.status >= 400 && response.status < 500) {
      throw new BBPlatform4XXError(url, response.status, responseBody.metadata);
    } else if (response.status >= 500 && response.status < 600) {
      throw new BBPlatformServerError(
        url,
        response.status,
        responseBody.metadata
      );
    } else {
      const message = `Received unexpected status code from ${method} ${url}: ${response.status}`;
      throw new BBPlatformClientError(message);
    }
  }

  static async apiGet<T = any>(props: RequestProps): Promise<T> {
    return (await BBPlatformClient.apiRequest<T>("get", props)).payload;
  }

  static async apiPost<T = any>(props: RequestProps): Promise<T> {
    return (await BBPlatformClient.apiRequest<T>("post", props)).payload;
  }

  static async apiPut<T = any>(props: RequestProps): Promise<T> {
    return (await BBPlatformClient.apiRequest<T>("put", props)).payload;
  }

  // static async isAuthorized():Promise<boolean> {
  // let authed = false
  // try {
  //   await BBPlatformClient.updateAccessToken()
  //   authed = true
  // }
  // catch (e) {
  //   console.error(`Got error while checking updating auth tokens`)
  //   console.error(e)
  // }
  // return authed
  // }

  /* ***************************************************************************
   * endpoint methods below
   ************************************************************************** */

  static async login(): Promise<LoginPayload> {
    return await BBPlatformClient.apiPost<LoginPayload>({
      url: `${Env.UAM_BASE_URL}/webclientLogin`,
      body: {},
    });
  }

  static async getGenerateAsset(
    body: CreateTxt2ImgGenerationJobDTO
  ): Promise<Txt2ImgGenerationJobCreationResultDTO> {
    return this.apiPost<any>({
      url: `${Env.BB2D_BASE_URL}/assetgeneration/txt2img`,
      body,
    });
  }

  static async getAssetGenerationProgress(
    jobId: string
  ): Promise<ImageGenerationJobCreationResultDTO> {
    return await this.apiGet<any>({
      url: `${Env.BB2D_BASE_URL}/assetgeneration/job/${jobId}/progress`,
    });
  }

  static async createBBDocRandomFile(body: {
    characterImage: string;
    obstacleImage: string;
    backgroundImage: string;
  }): Promise<any> {
    const index = Math.floor(Math.random() * Object.keys(templateType).length);
    const randomTemplateType = Object.values(templateType)[index];

    // will return URL of the generated file os s3 bucket
    return await this.apiPost<any>({
      url: `${Env.BB2D_BASE_URL}/assetgeneration/createbbdoc`,
      body: {
        ...body,
        templateType: templateType[randomTemplateType],
        //templateType: templateType["WORLD"]
      },
    });
  }

  static async getAssetImages(
    jobId: string
  ): Promise<{ jobId: string; presignedUrls: string[] }> {
    return await this.apiGet<any>({
      url: `${Env.BB2D_BASE_URL}/assetgeneration/job/${jobId}/result`,
    });
  }

  static async downloadBuildboxClient(
    platformDesired: any,
    version: keyof typeof BBDownloadVersion
  ) {
    return new Promise((resolve) => {
      if (!platformDesired) {
        // try to auto detect platform from browser
        const platform = (window as any).navigator.platform;

        if (platform) {
          const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
          const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];

          const foundMatch = (str: string) => str.includes(platform);
          if (windowsPlatforms.some(foundMatch)) {
            platformDesired = DownloadPlatforms.WINDOWS;
          } else if (macosPlatforms.some(foundMatch)) {
            platformDesired = DownloadPlatforms.MAC;
          } else {
            console.log(
              "unable to automatically determine download platform from ",
              platform
            );
            platformDesired = DownloadPlatforms.UNKNOWN;

            const dataLayer =
              ((window as any)["dataLayer"] && (window as any)["dataLayer"]) ||
              [];
            dataLayer.push({
              event: "couldNotDeterminePlatformDownload",
              payload: true,
            });
          }
        } else {
          console.log("unable to automatically determine download platform");
          platformDesired = DownloadPlatforms.UNKNOWN;

          const dataLayer = ((window as any).dataLayer =
            (window as any).dataLayer || []);
          dataLayer.push({
            event: "platformInfoNotAvailableDownload",
            payload: true,
          });
        }
      }

      // download!
      if (platformDesired === DownloadPlatforms.MAC) {
        console.log("auto downloading for mac!!!!!!");

        //TODO: Update GTM tags to track BB2

        const downloadLink = BBDownloadVersion[version].MAC;
        // const downloadType = BBDownloadVersion[version].APP;

        (window as any).location.href = downloadLink;

        const dataLayer = ((window as any).dataLayer =
          (window as any).dataLayer || []);
        dataLayer.push({
          event: "macDownload",
          payload: true,
        });

        // const savedGoogleClientId = localStorage.getItem("googleClientId");
        // BBPlatformClient.sendDownloadAnalytics(savedGoogleClientId, downloadType);

        resolve(downloadLink);
      } else if (platformDesired === DownloadPlatforms.WINDOWS) {
        console.log("auto downloading for windows!!!!!!");

        //TODO: Update GTM tags to track BB2

        const downloadLink = BBDownloadVersion[version].WIN;
        // const downloadType = BBDownloadVersion[version].APP;

        (window as any).location.href = downloadLink;

        const dataLayer = ((window as any).dataLayer =
          (window as any).dataLayer || []);
        dataLayer.push({
          event: "winDownload",
          payload: true,
        });

        // const savedGoogleClientId = localStorage.getItem("googleClientId");

        // BBPlatformClient.sendDownloadAnalytics(savedGoogleClientId, downloadType);

        resolve(downloadLink);
      } else {
        console.log("attempted download on non-mac/win platform");

        const dataLayer = ((window as any).dataLayer =
          (window as any).dataLayer || []);
        dataLayer.push({
          event: "unknownPlatformDownload",
          payload: true,
        });

        resolve(false);
      }
    }); // end of promise wrapper
  }

  // Now handleed at the createBBDocRandomFile myabe need in the future for another set of steps
  // static async uploadBBDocFile(body: UploadBBDocDTO):Promise<any> {
  //   return await this.apiPost<any>({
  //     url: `${Env.BB2D_BASE_URL}/bbdoc/upload`,
  //     body
  //   })
  // }

  static async createUserDownloadsRequest(clientId: any, version: string) {
    let error;
    for (let ii = 0; ii < 5; ii++) {
      try {
        let resp = await BBPlatformClient.sendUserDownloadRequestAnalytics(
          clientId,
          version
        );
        console.log("cudr result on try ", ii, resp);
        return resp;
      } catch (err) {
        console.log("cudr error: ", err);
        error = err;
      }
    }

    console.log("throw cudr error: ", error);
    throw error;
  }
  static async sendUserDownloadRequestAnalytics(
    clientId: any,
    version: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiPost<any>({
        url: `${Env.UAM_BASE_URL}/analytics/download`,
        body: {
          clientId: clientId,
          app: version,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res: any) => {
          if (res.status === 200 || res.status === 201) {
            resolve(res.data);
          }
        })
        .catch((error: any) => {
          if (error) {
            console.log(
              error,
              "error caught in createUserDownloadsRequest: ",
              error
            );
            console.log("errrrrrorrrrr ", error);
            reject(error);
          }
        });
    });
  }

  static async sendDownloadAnalytics(googleClientId: any, version: any) {
    try {
      console.log(googleClientId, "Downloads Component: send client id");
      console.log(version, "Downloads Component: app:", version);

      const analyticsResponse =
        await BBPlatformClient.createUserDownloadsRequest(
          googleClientId,
          version
        );
      console.log(
        analyticsResponse,
        "Downloads Component: Response from /createUserDownloadsRequest"
      );
    } catch (error) {
      console.log(
        error,
        "Downloads Component: Error caught from sendDownloadAnalytics"
      );
    }
  }

  static async testError(): Promise<any> {
    throw new BBPlatformClientError("test error");
  }
}
