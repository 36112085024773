import { FunctionComponent } from "react";

export enum FinalOptionsType {
    BUILD = "BUILD",
    REGENERATE = "REGENERATE",
    RESTART = "RESTART",
}

interface FinalOptionsProps {
    handleClick?: (opType: FinalOptionsType) => void;
}

const FinalOptions: FunctionComponent<FinalOptionsProps> = ({ handleClick }) => {
    return (
        <>
        <p>Tap on your choice to: <a href="#/" onClick={() => handleClick?.(FinalOptionsType.BUILD)}>BUILD</a> your game, <a href="#/" onClick={() => handleClick?.(FinalOptionsType.REGENERATE)}>REGENERATE</a> your assets, or <a href="#/" onClick={() => handleClick?.(FinalOptionsType.RESTART)}>RESTART</a>.</p>
        </>
    );
}

export default FinalOptions;