import styles from "./App.module.css";
import { PlaceholderFormComponent } from "./components/PlaceholderFormComponent";
import { ConfigProvider } from "antd";
import { AuthWrapper } from "./components/AuthWrapper";
import { useState } from "react";
import { TimestampedTokens } from "./api/BBPlatform";

function App() {
  const [authTokens, setAuthTokens] = useState<TimestampedTokens | null>(null);
  const [guestMode, setGuestMode] = useState<boolean>(true);
  const [createAccountMode, setCreateAccountMode] = useState<boolean>(true);

  function handleTokenChange(newTokens: TimestampedTokens | null): void {
    setAuthTokens(newTokens);
  }

  function handleClaimRequest(createAccountMode: boolean): void {
    setGuestMode(false);
    setAuthTokens(null);
    setCreateAccountMode(createAccountMode);
  }

  const [timestamp, setTimestamp] = useState<number>(0);

  const handleNullifyTokens = () => {
    setTimestamp(Date.now());
    window.location.reload();
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#F06422",
        },
        components: {
          Input: {
            colorBgContainer: "#505050",
            colorText: "#e0e0e0",
            colorTextPlaceholder: "#e0e0e0",
            // fontSize: 16,
            // padding: 20,
            colorBgContainerDisabled: "#505050"
          },
          Card: {
            borderRadius: 2,
            borderRadiusLG: 2,
            colorBorder: "#101010",
            colorBorderSecondary: "#101010",
            colorBgContainer: "#101010",
            colorText: "#AAA",
          },
          Progress: {
            colorText: "#e0e0e0",
          },
        },
      }}
    >
      <div className={styles.App}>
        <AuthWrapper
          guestAccountMode={guestMode}
          createAccountMode={createAccountMode}
          tokens={authTokens}
          onTokenChange={handleTokenChange}
          nullifyTokens={timestamp}
        >
          <PlaceholderFormComponent
            unauthorizedErrorHandler={handleNullifyTokens}
            onClaimRequest={handleClaimRequest}
          />
        </AuthWrapper>
      </div>
    </ConfigProvider>
  );
}

export default App;
